import styled from 'styled-components'
import { orange, gray, white, linear } from './colors'
import { borderRounded } from './radius'
import breakpoints from './breakpoints'

export const Button = styled.button`
  background: ${orange.base};
  border-radius: ${borderRounded['8']};
  border: 1px solid ${orange.base};
  font-weight: bold;
  color: ${white};
  padding: 11px 16px 12px 16px;
  font-size: 14px;
  width: 100%;
  display: block;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    width: auto;
  }

  &:hover {
    background: ${orange.base};
    color: ${orange.base};
    color: ${white};
    opacity: 0.9;
  }

  &:disabled {
    border: 1px solid ${gray[200]} !important;
    background-color: ${gray[200]} !important;
    color: ${white} !important;
    font-weight: 300 !important;
  }

  &.gradient {
    background: ${linear.orange};
    border: none;
    color: ${white};
    min-width: 220px;
    padding: 10px 16px;   
  }

  &.inverse {
    background: ${white};
    color: ${orange.base};
  }
`
