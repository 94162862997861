/* eslint-disable @typescript-eslint/typedef */
import axios from 'axios'
interface IMainEntity {
  question: string;
  answer: string;
}

interface IStep {
  position: string;
  image: string;
  name: string;
  text: string;
}

export const structuredData = (data: { type: string; content: []; name: string; description: string }) => {
  let parsedData

  switch (data.type) {
    case 'faq':
      parsedData = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          data.content.map((item: IMainEntity) => {
            return {
              '@type': 'Question',
              name: item.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: item.answer,
              },
            }
          }),
        ],
      }
      break

    case 'howTo':
      parsedData = {
        '@context': 'https://schema.org',
        '@type': 'HowTo',
        name: data.name,
        description: data.description,
        step: [
          data.content.map((item: IStep) => {
            return {
              '@type': 'HowToStep',
              position: item.position,
              image: item.image,
              name: item.name,
              text: item.text,
            }
          }),
        ],
      }
  }

  return parsedData
}

export const sendNewsletterFrom = async (name: string, email: string) => {
  try {
    const formatData = [
      {
        nome: name,
        email: email,
        aceite: true,
        conteudo01: 'News do blog',
        conteudo02: window.location.href,
      },
    ]

    const { data } = await axios.post(`${process.env.NEWSLETTER_ENDPOINT}`, formatData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (data.success) {
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const sendNewsletterArticleFrom = async (name: string, cpf: string, email: string, campanha: string, url: string) => {
  try {
    const formatData = [
      {
        CpfCnpj: cpf.replace(/\D/g, ''),
        nome: name,
        email: email,
        aceite: true,
        conteudo01: campanha,
        conteudo02: url,
      },
    ]

    const { data } = await axios.post(`${process.env.NEWSLETTER_ENDPOINT}`, formatData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (data.success) {
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const sendConsorcioForm = async (name: string, phone: string, email: string, bem: string, isClient: string) => {
  try {
    const formatData = [
      {
        nome: name,
        email: email,
        aceite: true,
        bem: bem,
        cliente: isClient,
        telefone: phone,
      },
    ]

    const { data } = await axios.post(`${process.env.CONSORCIO_ENDPOINT}`, formatData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (data.success) {
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const sendPrevidenciaForm = async (name: string, phone: string, email: string, isClient: string) => {
  try {
    const formatData = [
      {
        nome: name,
        email: email,
        cliente: isClient,
        telefone: phone,
        aceite: true,
      },
    ]

    const { data } = await axios.post(`${process.env.PREVIDENCIA_ENDPOINT}`, formatData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (data.success) {
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const convertsDateToTimestamp = (date: string) => {
  const timestampDate = new Date(date).getTime()
  return timestampDate
}

export const showUpdateTime = (publishDate: string, updateDate: string | undefined): boolean => {
  if (updateDate === undefined) {
    return false
  }

  const publishTimestamp = convertsDateToTimestamp(publishDate)
  const updateTimestamp = convertsDateToTimestamp(updateDate)

  if (updateTimestamp > publishTimestamp) {
    return true
  } else {
    return false
  }
}
